import { zodResolver } from "@hookform/resolvers/zod";
import classNames from "classnames";
import { useEffect, useState, type FC } from "react";
import { useWatch, type UseFormReturn } from "react-hook-form";
import z from "zod";

import { states } from "../../../data/states";
import type { BasicFormProps } from "../../../forms/form-context-wrapper/form-context-wrapper";
import { useCityAndState } from "../../../hooks/use-azure-maps-city-state";
import { defaultFamilyContactDetail, useFamilyConstants, useFamilyContactDetail } from "../../../hooks/use-families";
import { FormHelpers } from "../../forms";
import { InputField } from "../../forms/input-field";
import { PhoneNumberField } from "../../forms/phone-number-field";
import { SelectField } from "../../forms/select-field";
import {
  quickCreateStudentFormSchema,
  type QuickCreateStudentFormValues
} from "../../inquiry-form/quick-create-student";
import { LoadingButton } from "../../loading-button/loading-button";
import { useFormModal } from "../../modal/modal";
import { useDuplicateContactModal } from "../../inquiry-form/use-duplicate-contact-modal";
import { useCreateOrUpdateInquiry, useInquiry, type Inquiry } from "../../../hooks/use-inquiries";
import invariant from "tiny-invariant";
import { useAccessControl } from "../../../app/use-access-control";
import { STUDENT_FAMILY_CREATE_EDIT } from "../../../data/permissions";

const EditStudentContactForm: FC<BasicFormProps<QuickCreateStudentFormValues> & { readOnly?: boolean }> = ({
  methods,
  readOnly = false
}) => {
  const { activeTitles, activeGenders, activeGrades } = useFamilyConstants();
  const {
    register,
    setValue,
    control,
    formState: { errors }
  } = methods ?? ({ formState: {} } as UseFormReturn<QuickCreateStudentFormValues>);

  const zipCodeFieldValue = useWatch({ control, name: "zipcode", defaultValue: "" });
  const cityFieldValue = useWatch({ control, name: "city", defaultValue: "" });
  const stateFieldValue = useWatch({ control, name: "state", defaultValue: "" });

  const cityAndStateQuery = useCityAndState(zipCodeFieldValue, {
    enabled: FormHelpers.isValidZipcode(zipCodeFieldValue) && (!cityFieldValue || !stateFieldValue)
  });

  useEffect(() => {
    if (cityAndStateQuery?.data?.city && cityAndStateQuery?.data?.state) {
      setValue("city", cityAndStateQuery.data.city);
      setValue("state", cityAndStateQuery.data.state);
    }
  }, [cityAndStateQuery.data]);

  return (
    <>
      <div className="row form-standard">
        <div className="col col-12">
          <h3>Details</h3>
        </div>
        <div className="col col-12 col-md-6 col-lg-4">
          <SelectField label="Salutation" disableColClass disabled={readOnly} {...register("title")}>
            <option value="">Select Salutation</option>
            {activeTitles?.map(({ value, name }) => (
              <option key={value} value={value}>
                {name}
              </option>
            ))}
          </SelectField>
        </div>
        <div className="col col-12 col-md-6 col-lg-4">
          <InputField
            label="First Name"
            disableColClass
            error={errors.firstName}
            disabled={readOnly}
            {...register("firstName")}
          />
        </div>
        <div className="col col-12 col-md-6 col-lg-4">
          <InputField
            label="Last Name"
            disableColClass
            error={errors.lastName}
            disabled={readOnly}
            {...register("lastName")}
          />
        </div>
      </div>

      <div className="row form-standard mt-4">
        <h3 className="col col-12">Info</h3>
        <div className="col col-12 col-md-6">
          <SelectField label="Gender" disableColClass disabled={readOnly} {...register("gender")}>
            <option value="">Student Gender</option>
            {activeGenders?.map(({ value, name }) => (
              <option key={value} value={value}>
                {name}
              </option>
            ))}
          </SelectField>
        </div>
        <div className="col col-12 col-md-6">
          <InputField
            label="Age"
            type="number"
            error={errors.age}
            disabled={readOnly}
            {...register("age")}
            disableColClass
          />
        </div>
        <div className="col col-12 col-md-6">
          <SelectField
            label="Student Grade"
            disableColClass
            disabled={readOnly}
            isRequired
            error={errors.gradeId}
            {...register("gradeId")}
          >
            <option value="">Select a Grade</option>
            {activeGrades.map(({ value, name }) => (
              <option key={value} value={value}>
                {name}
              </option>
            ))}
          </SelectField>
        </div>
        <div className="col col-12 col-md-6">
          <InputField
            label="School"
            disableColClass
            error={errors.school}
            disabled={readOnly}
            {...register("school")}
          />
        </div>
      </div>

      <div className="row form-standard mt-4">
        <h3
          className={classNames({
            "is-invalid": errors["contact_root"]
          })}
        >
          Contact
        </h3>
        {!!errors["contact_root"] && (
          // @ts-expect-error
          <div className="invalid-feedback"> {errors["contact_root"]?.message}</div>
        )}
        <div className="col col-12">
          <InputField
            label="Email"
            validationState={errors.contact_root ? "error" : undefined}
            error={errors.email}
            disableColClass
            disabled={readOnly}
            {...register("email")}
          />
        </div>
        <div className="col col-12 col-md-6">
          <PhoneNumberField
            label="Home Phone"
            validationState={errors.contact_root ? "error" : undefined}
            error={errors.homePhone}
            disableColClass
            disabled={readOnly}
            {...register("homePhone")}
          />
        </div>
        <div className="col col-12 col-md-6">
          <PhoneNumberField
            label="Mobile Phone"
            validationState={errors.contact_root ? "error" : undefined}
            error={errors.personalPhone}
            disabled={readOnly}
            {...register("personalPhone")}
          />
        </div>
      </div>

      <div className="row form-standard mt-4">
        <h3>Address</h3>

        <div className="col col-12 col-md-6">
          <InputField
            label="Address Line 1"
            error={errors.addressLine1}
            disableColClass
            disabled={readOnly}
            {...register("addressLine1")}
          />
        </div>
        <div className="col col-12 col-md-6">
          <InputField
            label="Address Line 2"
            error={errors.addressLine2}
            disableColClass
            disabled={readOnly}
            {...register("addressLine2")}
          />
        </div>
        <div className="col col-12 col-md-6 col-lg-4">
          <InputField label="ZIP" error={errors.zipcode} disableColClass disabled={readOnly} {...register("zipcode")} />
        </div>
        <div className="col col-12 col-md-6 col-lg-4">
          <InputField label="City" disableColClass error={errors.city} disabled={readOnly} {...register("city")} />
        </div>
        <div className="col col-12 col-md-6 col-lg-4">
          <SelectField label="State" disableColClass disabled={readOnly} {...register("state")}>
            <option value="">Select State</option>
            {states.map(({ abbreviation, name }) => (
              <option key={abbreviation} value={abbreviation}>
                {name}
              </option>
            ))}
          </SelectField>
        </div>
      </div>
    </>
  );
};

const requiredFirstAndLastNameFields = z.object({
  firstName: z.string().trim().min(1, { message: "required" }),
  lastName: z.string().trim().min(1, { message: "required" })
});

const studentDetailFormSchema = z.intersection(quickCreateStudentFormSchema, requiredFirstAndLastNameFields);

export const useStudentDetailFormModal = (inquiry: Inquiry) => {
  const [contactId, setContactId] = useState<number>();
  const { contactDetail, createOrUpdateContactMutator: mutator } = useFamilyContactDetail("student", contactId);
  const inquiryQuery = useInquiry(inquiry.id);
  const createOrUpdateInquiryMutator = useCreateOrUpdateInquiry();
  const { hasPermission } = useAccessControl();

  const { renderDuplicateContactModal, onSubmitError } = useDuplicateContactModal({
    onSubmit: contact => {
      invariant(inquiryQuery.data, "inquiry data is required");
      createOrUpdateInquiryMutator.mutate(
        {
          inquiryId: inquiry.id,
          formData: {
            ...inquiryQuery.data,
            studentId: contact.id
          }
        },
        {
          onSuccess: ({ student }) => {
            setContactId(student.id);
          }
        }
      );
    },
    isSubmitDisabled: !inquiryQuery.data
  });

  const {
    hide: hideStudentDetailFormModal,
    show,
    formId,
    renderModal: renderFormModal
  } = useFormModal({
    name: "edit-student-contact-form",
    useFormProps: {
      defaultValues: defaultFamilyContactDetail,
      values: contactDetail,
      resolver: zodResolver(studentDetailFormSchema),
      mode: "onTouched"
    },
    onSubmit: formValues => {
      mutator.mutate(
        { id: contactId, payload: formValues },
        { onSuccess: hideStudentDetailFormModal, onError: onSubmitError }
      );
    }
  });

  const hasEditPermission = hasPermission && hasPermission(STUDENT_FAMILY_CREATE_EDIT);

  const renderStudentDetailFormModal = () =>
    renderFormModal({
      title: `${hasEditPermission ? "Edit" : "View"} Student Contact - ${contactDetail?.lastName}, ${contactDetail?.firstName}`,
      body: <EditStudentContactForm readOnly={!hasEditPermission} />,
      footer: (
        <>
          <button className="btn btn-secondary" onClick={hideStudentDetailFormModal}>
            Cancel
          </button>
          {hasEditPermission && (
            <LoadingButton
              className="btn btn-primary"
              type="submit"
              form={formId}
              isLoading={mutator.isLoading}
              loadingMessage="Saving..."
            >
              Save
            </LoadingButton>
          )}
        </>
      ),
      size: "lg",
      fullscreen: "lg-down"
    });

  const showStudentDetailFormModal = (id: number) => {
    setContactId(id);
    show();
  };

  return {
    hideStudentDetailFormModal,
    showStudentDetailFormModal,
    renderStudentDetailFormModal,
    renderDuplicateContactModal
  };
};
