import { useEffect, useState } from "react";
import { isPossiblePhoneNumber, parsePhoneNumber, isValidPhoneNumber as isPhoneNumberValid } from "libphonenumber-js";
import moment from "moment";
import { z } from "zod";

import { CenterHoliday, CenterDetail } from "../../hooks/use-center";
import { AccountInformation } from "../../hooks/use-user";
import { ReferenceDataCollection } from "../../hooks/use-reference-data";
import { Note } from "../../hooks/use-notes";

// note that validating email addresses perfectly is a known nightmare because capitalism.
// this is simple and covers like 99.9999% of cases and while it might theoretically let through
// a bad one, it won't reject good ones, fwiw the only certain way to validate an email is to
// use a send/response process
export const isValidEmailAddress = (input: string, required = true) => {
  if (!input || !input.trim()) {
    return !required;
  }
  return /\S+@\S+\.\S+/.test(input);
};

export const isValidOptionalEmailAddress = (input: string) => {
  return isValidEmailAddress(input, false);
};

export const isValidCenterDetail = (input: CenterDetail) => {
  return (
    isValidRequiredString(input.name) &&
    isValidRequiredString(input.line1) &&
    isValidRequiredString(input.city) &&
    isValidRequiredString(input.state) &&
    isValidZipcode(input.zipcode) &&
    isValidEmailAddress(input.email) &&
    isValidPhoneNumber(input.phoneTollfree) &&
    isValidPhoneNumber(input.phoneDirect) &&
    isValidOptionalPhoneNumber(input.phoneAdvertised)
  );
};

export const isValidNote = (input: Note) => {
  return isValidRequiredString(input.title) && isValidRequiredString(input.notes);
};

export const formatPhoneNumber = (input: string | undefined) => {
  if (!input) {
    return "";
  }
  if (!!input && !!input.trim() && isPossiblePhoneNumber(input, "US") && isPhoneNumberValid(input, "US")) {
    const phone = parsePhoneNumber(input, "US");
    return phone.formatNational().replace(/[()]/g, "").replace(" ", "-");
  }
  return input;
};

export const isValidOptionalPhoneNumber = (input?: string) => {
  return !input?.trim() || isValidPhoneNumber(input);
};

export const isValidPhoneNumber = (input: string) => {
  if (!input.trim()) {
    return false;
  }
  return isPossiblePhoneNumber(input, "US") && isPhoneNumberValid(input, "US");
};

export const isValidOptionalZipcode = (input?: string) => {
  return !input?.trim() || isValidZipcode(input);
};

export const isValidZipcode = (input: string) => {
  if (!input || !input.trim()) {
    return false;
  }
  const length = input.replace(/[^0-9]/g, "").length;
  return length === 5 || length === 9;
};

export const isValidJobTitle = (input: string) => {
  if (input === undefined) {
    return false;
  }

  return input.length <= 100;
};

export const isValidRequiredString = (input?: string, maxLength?: number) => {
  if (!input?.trim?.().length) {
    return false;
  }

  if (!maxLength) {
    return true;
  }

  return input.trim().length <= maxLength;
};

// yes there is method to this madness
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const isValidOptionalString = (input: string) => true;

export const formatDateString = (input: string | undefined, format?: string) => {
  if (!input?.trim()) {
    return "";
  }
  const mom = moment(input);
  if (mom.isValid()) {
    return mom.format(format || "MMM D, YYYY");
  } else {
    return "";
  }
};

//assume max is always valid
export const isValidDateString = (input: string | undefined, required = false, max?: string) => {
  if (!input?.trim()) {
    return !required;
  }
  if (moment(input).isValid()) {
    return max ? moment(input).isSame(max) || moment(input).isBefore(max) : true;
  }
  return false;
};

export const isValidRequiredDateString = (input: string | undefined, max?: string) => {
  return isValidDateString(input, true, max);
};

export const isValidCenterHoliday = (input: CenterHoliday) => {
  return isValidRequiredString(input.name, 255) && isValidRequiredDateString(input.date);
};

export const translateWeekday = (value: string | number, referenceData: ReferenceDataCollection | undefined) => {
  if (!referenceData || !referenceData.weekdays) {
    return value;
  } else {
    // have to coerce the value to a string because reference data has them as
    // strings while end point for operating hours has them as a number
    return referenceData.weekdays.find(wd => wd.value === `${value}`)?.name ?? value;
  }
};

export const parseToMoment = (input: string) => moment(input, "HH:mm:ss");

export const format24HourTimeString = (value: string, includeMeridian = true) => {
  if (!value) {
    return "";
  } else if (includeMeridian) {
    return parseToMoment(value).format("h:mm a");
  } else {
    return parseToMoment(value).format("h:mm");
  }
};

export const format24HourTimeStringForApi = (value: string) => {
  if (!value) {
    return "";
  } else {
    return parseToMoment(value).format("HH:mm:ss");
  }
};

export const addTime = (value?: string, length?: number, duration: "h" | "m" | "s" | "ms" = "m") => {
  if (!value?.length) {
    return "";
  }
  return parseToMoment(value)
    .add(length || 0, duration)
    .format("HH:mm:ss");
};

export const isTimeWithinRange = (rangeStart: string, rangeEnd: string, validateTime: string) => {
  if (!rangeEnd || !rangeStart || !validateTime) {
    return false;
  }

  const start = parseToMoment(rangeStart);
  const end = parseToMoment(rangeEnd);
  const time = parseToMoment(validateTime);

  return time.isSameOrAfter(start) && time.isSameOrBefore(end);
};

//assume max is always valid
export const isValidTimeString = (input: string | undefined, required = false, max?: string) => {
  if (!input?.trim?.()) {
    return !required;
  }
  if (moment(input, "HH:mm:ss").isValid()) {
    return max
      ? moment(input, "HH:mm:ss").isSame(moment(max, "HH:mm:ss")) ||
          moment(input, "HH:mm:ss").isBefore(moment(max, "HH:mm:ss"))
      : true;
  }
  return false;
};

export const isValidRequiredTimeString = (input: string | undefined, max?: string) => {
  return isValidTimeString(input, true, max);
};

export const isValidAccountInformation = (input: AccountInformation) => {
  return (
    isValidRequiredString(input.position) &&
    isValidRequiredString(input.role) &&
    isValidRequiredDateString(input.startDate) &&
    input.centers.length > 0
  );
};

export function createValidatorHook<T, U = void>(validator: (input: T, optionalParameter: U) => boolean) {
  return function (value: T, optionalParameter: U) {
    const [isValid, setIsValid] = useState<boolean>(validator(value, optionalParameter));

    useEffect(() => {
      setIsValid(validator(value, optionalParameter));
    }, [value]);

    return isValid;
  };
}

export const isValidOptionalEmployeeNumber = (input: string | number | null) => {
  return typeof input === "number" || !input?.length || /^\d{1,15}$/.test(input);
};

export type InProgressFormValues<T> =
  T extends Array<infer TElement>
    ? InProgressFormValues<TElement>[]
    : T extends Date
      ? Date | ""
      : // eslint-disable-next-line @typescript-eslint/no-explicit-any
        T extends Record<string, any>
        ? { [TKey in keyof T]: InProgressFormValues<T[TKey]> }
        : T | "";

export const isoDateTimeStringToTimeString = (isoDateTimeString: string, centerTimezone: string) =>
  moment(isoDateTimeString).tz(centerTimezone).format("h:mm a");

export const processErrorsFromResponse = async (response: Response) => {
  // If we get a 422 "Unprocessable Entity" response, extract the json to get the field and error message
  if (response.status === 422) {
    const errorResponse = await response.json();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const errors = errorResponse.detail.map((error: any) => ({
      field: error.loc.pop(),
      message: error.msg
    }));

    throw new AggregateError(errors);
  }
};

const urlPattern = new RegExp(
  "^([a-zA-Z]+:\\/\\/)?" + // protocol
    "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
    "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR IP (v4) address
    "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
    "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
    "(\\#[-a-z\\d_]*)?$", // fragment locator
  "i"
);

export const isValidUrl = (str: string) => {
  return urlPattern.test(str);
};

export const dollarsSchema = z.coerce
  .string()
  // Input type="number" allows for '+', '-', 'e'.  e.g. +1e-2 = 0.01. When it has those characters
  // and it cannot be represented as a number it's value = "". So we need to check to make sure that
  // the value is not "". the following !isNaN(Number(value)) check is NOT necessary as the input
  // already checks this but left there for some sanity
  .refine(value => value && !isNaN(Number(value)))
  .transform(value => Number(value))
  .pipe(
    z
      .number()
      .min(0, { message: "Value must be positive" })
      .lte(99999.99, { message: "Value must be less than or equal to 99999.99" })
      .step(0.01, { message: "Value must be in increments of 0.01" })
  );

export const zipcodeSchema = z.string().refine(value => {
  if (value.length === 5) {
    return /^\d{5}$/.test(value);
  } else if (value.length === 10) {
    return /^\d{5}-\d{4}$/.test(value);
  } else {
    return value.length === 0;
  }
}, "ZIP invalid");
