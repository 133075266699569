import { useQuery } from "@tanstack/react-query";
import { useAccessToken } from "../use-access-token";
import {
  EXTENDED_CACHE_TIME,
  type ReferenceRecord,
  referenceRecordsToLookup,
  referenceRecordToCodeLookup
} from "../use-reference-data";
import { callLcosService } from "../../data/lcosServices";
import { CODES } from "../../data/dynamics-codes";

interface InquiryConstants {
  appointmentStatuses: ReferenceRecord[];
  appointmentCancelChoices: ReferenceRecord[];
  discountReasons: ReferenceRecord[];
  discountTypes: ReferenceRecord[];
  objections: ReferenceRecord[];
  optOuts: ReferenceRecord[];
  referralSources: ReferenceRecord[];
  contactMethodSources: ReferenceRecord[];
}

export const useInquiryConstants = () => {
  const { getToken } = useAccessToken();

  return useQuery({
    queryKey: ["inquiryConstants"] as const,
    queryFn: async () => getInquiryConstants(await getToken()),
    cacheTime: EXTENDED_CACHE_TIME,
    staleTime: EXTENDED_CACHE_TIME,
    select: data => {
      const APPOINTMENT_STATUS_CODE_LOOKUP = referenceRecordToCodeLookup(data.appointmentStatuses);
      const APPOINTMENT_STATUS_SCHEDULED_ID = APPOINTMENT_STATUS_CODE_LOOKUP[CODES.APPOINTMENT_STATUSES.SCHEDULED]
        .value as string;
      const APPOINTMENT_STATUS_RESCHEDULED_ID = APPOINTMENT_STATUS_CODE_LOOKUP[CODES.APPOINTMENT_STATUSES.RESCHEDULED]
        .value as string;
      const APPOINTMENT_STATUS_AS_SCHEDULED_ID = APPOINTMENT_STATUS_CODE_LOOKUP[CODES.APPOINTMENT_STATUSES.AS_SCHEDULED]
        .value as string;
      const APPOINTMENT_STATUS_CANCELLED_ID = APPOINTMENT_STATUS_CODE_LOOKUP[CODES.APPOINTMENT_STATUSES.CANCELLED]
        .value as string;
      const APPOINTMENT_STATUS_NO_SHOW_ID = APPOINTMENT_STATUS_CODE_LOOKUP[CODES.APPOINTMENT_STATUSES.NO_SHOW]
        .value as string;

      return {
        ...data,
        appointmentStatusesLookup: referenceRecordsToLookup(data.appointmentStatuses),
        activeAppointmentStatuses: data.appointmentStatuses.filter(appointmentStatus => appointmentStatus.isActive),
        activeAppointmentCancelChoices: data.appointmentCancelChoices.filter(
          appointmentCancelChoice => appointmentCancelChoice.isActive
        ),
        activeDiscountReasons: data.discountReasons.filter(discountReason => discountReason.isActive),
        activeDiscountTypes: data.discountTypes.filter(discountType => discountType.isActive),
        activeObjections: data.objections.filter(objection => objection.isActive),
        activeOptOuts: data.optOuts.filter(optOut => optOut.isActive),
        activeReferralSources: data.referralSources.filter(referralSource => referralSource.isActive),
        contactMethodSources: data.contactMethodSources.filter(contactMethodSource => contactMethodSource.isActive),
        APPOINTMENT_STATUS_CODE_LOOKUP,
        APPOINTMENT_STATUS_SCHEDULED_ID,
        APPOINTMENT_STATUS_RESCHEDULED_ID,
        APPOINTMENT_STATUS_AS_SCHEDULED_ID,
        APPOINTMENT_STATUS_CANCELLED_ID,
        APPOINTMENT_STATUS_NO_SHOW_ID
      };
    }
  });
};

export const getInquiryConstants = (accessToken: string) => {
  return callLcosService<InquiryConstants>(accessToken, `/api/inquiries/constants`);
};
